import { useNotification } from 'components/Notification/Notification';
import { PAGINATION } from 'Constants';

class LocationService {
  /**
   * Find location by id
   * @param {Object} query
   * @param {Number} id
   * @returns {Promise}
   */
  static async index(query, id) {
    const { data } = await query.refetch({ id });
    return data.getLocation;
  }

  /**
   * Get all locations
   * @param {Object} query
   * @param {String} orderBy
   * @param {String} sortOrder
   * @param {Number} rows
   * @param {Number} start
   * @param {Object} params
   * @returns {Promise}
   */
  static async all(
    query,
    orderBy,
    sortOrder,
    start,
    params,
    rows = PAGINATION.ITEMS_PER_PAGE,
  ) {
    const { data } = await query.refetch({
      orderBy,
      sortOrder,
      rows,
      start,
      searchParam: params,
    });
    return data.searchLocations;
  }

  /**
   * Get by organization
   * @param {Object} query
   * @param {String} organizationId
   * @returns {Promise}
   */
  static async getByOrganization(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getLocations;
  }

  /**
   * Create new location
   * @param {Promise} mutation
   * @param {Object} input
   * @returns {Promise}
   */
  static async create(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.createLocation;
  }


  /**
   * Bulk upload new location
   * @param {Promise} mutation
   * @param {Object} input
   * @returns {Promise}
   */

  static async bulkUploadLocations(mutation, input ) {
  console.log('Inside location service, preparing mutation...');
  console.log('JSON Data:', input);
  const { data } = await mutation({
      variables: {
        input, // Ensure this is a valid JSON string
      },
    });
}


  /**
   * Update location
   * @param {Promise} mutation
   * @param {Number} id
   * @param {Object} input
   * @returns {Promise}
   */
  static async update(mutation, id, input) {
    const { data } = await mutation({ variables: { id, input } });
    return data.updateLocation;
  }

  /**
   * Archive location
   * @param {Object} mutation
   * @param {Number} id
   * @param {Boolean} archived
   * @returns {Promise}
   */
  static async archive(mutation, id, archived) {
    const { data } = await mutation({ variables: { id, input: { archived } } });
    return data.updateLocation;
  }

  /**
   * Delete location
   * @param {Object} mutation
   * @param {String} id
   * @returns {Promise}
   */
  static async delete(mutation, id) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to delete this Location?',
      'Delete Location',
    );

    if (des) {
      const { data } = await mutation({ variables: { id } });
      return data.deleteLocation;
    }

    return null;
  }

  /**
   * Archive many locations.
   * @param {Object} mutation
   * @param {number[]} ids
   * @returns {Promise}
   */
  static async archiveMany(mutation, ids, archived = true) {
    return Promise.all(
      ids.map((id) => mutation({ variables: { id, input: { archived } } })),
    );
  }

  /**
   * Delete many locations.
   * @param {Object} mutation
   * @param {number[]} ids
   * @returns {Promise}
   */
  static async deleteMany(mutation, ids) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      `Are you sure you want to delete ${ids.length} locations?`,
      'Delete locations',
    );

    if (des) {
      return Promise.all(ids.map((id) => mutation({ variables: { id } })));
    }

    return null;
  }
}

export default LocationService;
