import * as Yup from 'yup';

import {
  DATE_VALIDATION,
  NUDGE_TYPES,
  REQUIRED_FIELD,
  rfc1738UrlRegex,
} from 'Constants';

const validationSchema = () => Yup.object({
  environment: Yup.object().required(REQUIRED_FIELD),
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string()
    .trim()
    .nullable()
    .default(null),
  messageCategory: Yup.object()
    .nullable()
    .default(null)
    .required(REQUIRED_FIELD),
  title: Yup.string()
    .trim()
    .nullable()
    .default(null)
    .when('isDraft', {
            is: (isDraft) => !isDraft,
            then: () => Yup.string().required(REQUIRED_FIELD),
            otherwise: () => Yup.string().notRequired()}),
  body: Yup.string()
    .trim()
    .nullable()
    .default(null)
    .when('isDraft', {
            is: (isDraft) => !isDraft,
            then: () => Yup.string().required(REQUIRED_FIELD),
            otherwise: () => Yup.string().notRequired()}),
   url: Yup.string().trim().nullable()
    .when('urlLinkType.value', (urlLinkTypeValue, schema) => {
      const processedValue = Array.isArray(urlLinkTypeValue) ? urlLinkTypeValue[0] : urlLinkTypeValue;
      if (processedValue === 'Weblink') {
        return schema.test('urlTestType', 'Please enter a valid URL', function (value) {
          const { url } = this.parent || {};
          const regex = new RegExp(rfc1738UrlRegex);
          return url && url.slice(0, 4) !== 'tel:' ? regex.test(url) : true;
        });
      } else {
        return schema.nullable();
      }
    }),
  scheduleDateTime: Yup.date()
  .nullable()
  .default(null)
  .when(['nudgeType', 'isDraft'], {
    is: (nudgeType, isDraft) => nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID,
    then: (schema) => schema
      .when('scheduleNow', (scheduleNow, schema) => {
        return scheduleNow
          ? schema
          : schema
              .min(new Date(), DATE_VALIDATION)
              .when('isDraft', (isDraft, schema) => (
                isDraft ? schema : schema.required(REQUIRED_FIELD)
              ));
      })
      .test('is-future-date', 'Date-time must be in the future', function (value) {
        const { isDraft, scheduleNow } = this.parent;
        return scheduleNow || (value && value > new Date());
      }),
    otherwise: () => Yup.date().nullable().default(null),
  }),
  segmentId: Yup.object()
    .nullable()
    .default(null)
    .when('isDraft', {
            is: (isDraft) => !isDraft,
            then: () => Yup.object().required(REQUIRED_FIELD),
            otherwise: () => Yup.object().notRequired()}),
  scheduleTimeHour: Yup.object()
    .nullable()
    .default(null),
  scheduleTimeMinute: Yup.object()
    .nullable()
    .default(null),
  scheduleTimeType: Yup.object()
    .nullable()
    .default(null),
});

export default validationSchema;
