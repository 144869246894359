import { gql } from '@apollo/client';

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocationMutation($input: LocationInput!) {
    createLocation(input: $input) {
      location { id }
    }
  }
`;

export const BULK_UPLOAD_LOCATIONS_MUTATION = gql`
  mutation BulkUploadLocationsMutation($input: BulkLocationInput!) {
    bulkUploadLocations(input: $input) {
      location { id }
    }
  }
`;


export const UPDATE_LOCATION_MUTATION = gql`
  mutation UpdateLocationMutation($id: ID!, $input: LocationInput!) {
    updateLocation(id: $id, input: $input) {
      location {
        id
        name
        description
        latitude
        longitude
        address
        state
        city
        country
        zip
        organization {
          id
          name
          website
          federationUrl
          larkyOrganizationId
          larkyEmail
          larkyPassword
          active
          created
          updated
        } 
        active
        archived
        created
        updated
      } 
    }
  }
`;

export const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocationMutation($id: ID!) {
    deleteLocation(id: $id) {
      ok
    }
  }
`;
