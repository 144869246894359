// Dependencies
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Button,
  Table,
  DropdownButton,
  Dropdown,
  Form,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';

// Styles
import styles from './CustomTable.module.scss';

// Components
import { useNotification } from 'components/Notification/Notification';

// Utils
import {buildQueryParamsURL, getUser, getUserFeature} from 'Utils';

// Constants
import { PAGINATION, CUSTOM_TABLE } from 'Constants';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import Pagination from './Pagination/Pagination';
import Analytics from '../../libs/Analytics';

const FILE_NAME = 'CustomTable.jsx';

function CustomTable({
  getData,
  availableTypes,
  customRow,
  columns,
  searchKeys,
  enabledFilters,
  filterAttrs,
  isFilterable,
  isPaginable,
  selectActions,
  selectFilters,
  fetchDataObj,
  statusType,
  itemsPerPage,
  reloadDataToggle,
  isCenteredCells,
  messageCategoryFilters,
  displayBulkActionCallback,
  displayBulkAction,
  tableType
}) {
  const location = useLocation();
  const history = useHistory();
  const notification = useNotification();
  const params = new URLSearchParams(location.search);
  const [userHasTrellance, setUserHasTrellance] = useState(false);
  const [userHasDeepTarget, setUserHasDeepTarget] = useState(false);
  const [userHasHubSpot, setUserHasHubSpot] = useState(false);
  const user = getUser();

  useEffect(async () => {
    const hasTrellance = await getUserFeature('trellance_segmentation');
    await setUserHasTrellance(hasTrellance);
    const hasDeepTarget = await getUserFeature('deeptarget_segmentation');
    await setUserHasDeepTarget(hasDeepTarget);
    const hasHubSpot = await getUserFeature('hubspot_segmentation');
    await setUserHasHubSpot(hasHubSpot);
  }, [user.featureFlags]);


  // Map filters to search keys
  const SEARCH_KEYS_MAP = {
    q: 'searchQuery',
    time: 'timeFilter',
    sort: 'sortFilter',
    type: 'typeFilter',
    filterStatus: 'statusFilter',
    filterCreatedAfter: 'createdAfterFilter',
    filterKeyword: 'searchQuery',
    filterCustomType: 'segmentTypeFilter',
    category_key: 'categoryTypeFilter',
  };
  const PAYLOAD_SEARCH_KEYS = {
    q: 'searchQuery',
    time: 'timeFilter',
    type: 'typeFilter',
    filterStatus: 'statusFilter',
    filterCreatedAfter: 'createdAfterFilter',
    filterKeyword: 'searchQuery',
    filterCustomType: 'segmentTypeFilter',
    category_key: 'categoryTypeFilter',
  };
  // init filter params values
  const FILTERS_PARAMS = {
    searchQuery: '',
    timeFilter: 0,
    sortFilter: 0,
    typeFilter: 0,
    statusFilter: null,
    createdAfterFilter: 0,
    filterKeyword: '',
    segmentTypeFilter: 0,
    categoryTypeFilter: 0,
  };

  // To make any of the following filters visible, include them in the enabledFilters array
  // ex: enabledFilters={['statusFilter']}
  // will enable the statusFilter
  const ENABLED_FILTERS = {
    searchQuery: false,
    selectFilter: false,
    timeFilter: false,
    sortFilter: false,
    typeFilter: null,
    statusFilter: null,
    createdAfterFilter: null,
    segmentTypeFilter: null,
    categoryTypeFilter: false,
  };

  // enable filters
  enabledFilters.forEach((filter) => {
    // eslint-disable-next-line no-prototype-builtins
    if (ENABLED_FILTERS.hasOwnProperty(filter)) {
      ENABLED_FILTERS[filter] = true;
    }
  });

  // Gets filter parameters values from URI params
  searchKeys.forEach((searchKey) => {
    // eslint-disable-next-line no-prototype-builtins

    if (SEARCH_KEYS_MAP.hasOwnProperty(searchKey) && params.get(searchKey)) {
      FILTERS_PARAMS[SEARCH_KEYS_MAP[searchKey]] = decodeURIComponent(params.get(searchKey));
    }
  });
  const searchType = { drafts: false, archived: false };

  availableTypes.forEach((type) => {
    if (location.pathname.includes(type)) {
      Object.assign(searchType, { [type]: true });
    }
  });

  const [rows, setRows] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [start, setStart] = useState(0);
  const [searchQuery, setSearchQuery] = useState(FILTERS_PARAMS.searchQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState({value: 0, label: "All"});

  // default constant items per page - 25
  const { ITEMS_PER_PAGE } = PAGINATION;

  const {
    FILTER_SORT,
    FILTER_TIME,
    FILTER_TYPE,
    FILTER_SEGMENTS_STATUS,
    FILTER_CAMPAIGN_STATUS,
    FILTER_CREATED_AFTER,
    FILTER_CUSTOM_TYPE,
    FILTER_NUDGE_STATUS,
      FILTER_LOCATIONNUDGE_STATUS
  } = CUSTOM_TABLE;
  // determine what status options to use for dropdown
  let FILTER_STATUS = FILTER_SEGMENTS_STATUS;
  if (statusType.toLowerCase() === 'campaigns') {
    FILTER_STATUS = FILTER_CAMPAIGN_STATUS;
  }

  if (statusType.toLowerCase() === 'nudges') {
    FILTER_STATUS = FILTER_NUDGE_STATUS;
  }

  if (statusType.toLowerCase() === 'locationnudges') {
    FILTER_STATUS = FILTER_LOCATIONNUDGE_STATUS;
  }

  // find index for message category list
  const findMessageCategoryValueOption = (messageCategoryValueOption) => {
    return messageCategoryFilters ? messageCategoryFilters.find(
            ({ value }) => value === Number(messageCategoryValueOption)) : null
  };

  useEffect(async () => {

    if (FILTERS_PARAMS.categoryTypeFilter != null && messageCategoryFilters.length) {
      await setCategoryIndex(findMessageCategoryValueOption(FILTERS_PARAMS.categoryTypeFilter));
    }

  }, [FILTERS_PARAMS]);



  const sortOption = FILTER_SORT[FILTERS_PARAMS.sortFilter];
  const timeOption = FILTER_TIME[FILTERS_PARAMS.timeFilter];
  const typeOption = FILTER_TYPE[FILTERS_PARAMS.typeFilter || 0];
  const statusOption = FILTER_STATUS[FILTERS_PARAMS.statusFilter || 0];
  const createdAfterOption = FILTER_CREATED_AFTER[FILTERS_PARAMS.createdAfterFilter];
  const customTypeOption = FILTER_CUSTOM_TYPE[FILTERS_PARAMS.segmentTypeFilter];
  const categoryTypeOption = categoryIndex;

  const filterParams = {
    select: 0,
    q: searchQuery,
    filterKeyword: searchQuery,
    time: FILTERS_PARAMS.timeFilter,
    sort: FILTERS_PARAMS.sortFilter,
    type: FILTERS_PARAMS.typeFilter,
    filterStatus: FILTERS_PARAMS.statusFilter,
    filterCreatedAfter: FILTERS_PARAMS.createdAfterFilter,
    filterCustomType: FILTERS_PARAMS.segmentTypeFilter,
    category_key: FILTERS_PARAMS.categoryTypeFilter,
  };
  itemsPerPage = itemsPerPage || ITEMS_PER_PAGE;

  function handlePagination(pageAction) {
    setStart(start + itemsPerPage * pageAction);
  }

  function handleSubmit(searchParams) {
    const {
      q,
      time,
      sort,
      type,
      filterStatus,
      filterCreatedAfter,
      filterKeyword,
      filterCustomType,
      category_key,
    } = searchParams;


    setStart(0);
    const values = {
      q, time, sort, type, filterStatus, filterCreatedAfter, filterKeyword, filterCustomType, category_key,
    };
    const args = searchKeys.reduce((result, key) => {
      if (values[key] != null) result.push({ key, value: values[key] });
      return result;
    }, []);

    history.replace(`${location.pathname}${buildQueryParamsURL(args)}`);
  }

  function handleChange(title, value) {
    let parseValue = value;
    if (typeof value === 'number') {
      parseValue = value >= 1 ? value - 1 : '';
    }

    Object.assign(filterParams, { [title]: parseValue });
    handleSubmit(filterParams);
  }

  // specifically for handling Category because it comes from the back end and has different qualities
  function handleCategoryChange(title, value) {
    filterParams.category_key = value;
    handleSubmit(filterParams);
  }

  function handleSearchByQuery(e) {
    if (e.charCode === 13) {
      handleChange('q', searchQuery);
    }
  }

  function handleApply() {
    handleSubmit(filterParams);
  }

  function handleReset() {
    setSearchQuery('');
    history.replace(location.pathname);
  }

  function handleSelectRow(id, value) {
    const newRows = [...rows];
    const newRow = newRows.find((row) => row.id === id);
    Object.assign(newRow, { selected: value });
    setRows(newRows);
  }

  /**
   *
   * @param {boolean} value
   */
  function setAllRowsSelected(selected) {
    setRows(rows.map((row) => Object.assign(row, { selected })));
  }



  function handleBulkAction() {
    displayBulkActionCallback(!displayBulkAction)
  }

  function handleSelectAllByFilter(filter) {
    if (filter === 'all') {
      setAllRowsSelected(true);
      setSelectAll(true);
    } else if (filter === 'none') {
      setAllRowsSelected(false);
      setSelectAll(false);
    } else {
      setRows(
        rows.map((row) => Object.assign(row, { selected: !!row[filter] })),
      );
    }
  }

  const updateCallback = useCallback(
    () => {
      async function fetch(
        startParam,
        sortOptionParam,
        typeOptionParam,
        timeOptionParam,
        searchQueryParam,
        filterStatusOptionParam,
        filterCreatedAfterOptionParam,
        filterCustomTypeParam,
        category_keyParam,
      ) {
        try {
          setIsLoading(true);
          const searchParams = loadSearchParams();

          if (FILTERS_PARAMS.typeFilter !== null && ENABLED_FILTERS.typeFilter) {
            Object.assign(searchParams, { type: typeOptionParam.value });
          }
          if (availableTypes.includes('drafts')) Object.assign(searchParams, { isDraft: searchType.drafts });
          if (availableTypes.includes('archived')) Object.assign(searchParams, { archived: searchType.archived });
          const data = await getData(
            sortOptionParam.field,
            sortOptionParam.order,
            startParam,
            searchParams,
          );

          // The first check the result for nudges filters and locations
          // The second is for segments filters
          // else if there are definitely  no results
          if (data && data.totalResults) {
            setTotalResults(data.totalResults);
            setRows(data.hits.map((row) => ({ ...row, selected: false })));
          } else if (data && data.length > 0) {
            setTotalResults(data.length);
            setRows(data.map((row) => ({ ...row, selected: false })));
          } else {
            setTotalResults(1);
            setRows([]);
          }
          setIsLoading(false);
          setLoaded(true);
        } catch (err) {
          Analytics.trackApplicationError(err.message, FILE_NAME, 'fetch');
          notification.alert(err.message, 'danger');
        }

        function loadSearchParams() {
          /*
            Here is where the searchParam object gets build.
            REMEMBER: this is going to be sent as a parameter through GraphQL, so it needs to match
            the parameters defined in the GraphQL queries/mutations for that function.
          */
          const payload = {};
          enabledFilters.forEach((enabledFilter) => {
            for (const [key, value] of Object.entries(PAYLOAD_SEARCH_KEYS)) {
              if (enabledFilter === value && searchKeys.includes(key)) {
                if (value === 'timeFilter') {
                  payload.createdSince = timeOptionParam.value;
                } else if (value === 'createdAfterFilter') {
                  if (filterCreatedAfterOptionParam.value) {
                    payload.filterCreatedAfter = filterCreatedAfterOptionParam.value;
                  }
                } else if (value === 'segmentTypeFilter') {
                  payload.computeType = filterCustomTypeParam.value;
                } else if (value === 'statusFilter') {
                  payload.filterStatus = !filterStatusOptionParam ? '' : filterStatusOptionParam.value;
                } else if (value === 'categoryTypeFilter') {
                  payload.categoryKey = (category_keyParam)? category_keyParam.value : 0;
                } else {
                  payload[key] = FILTERS_PARAMS[SEARCH_KEYS_MAP[key]];
                }
                break;
              }
            }
          });
          return payload;
        }
      }
      fetch(
        start,
        sortOption,
        typeOption,
        timeOption,
        FILTERS_PARAMS.searchQuery,
        statusOption,
        createdAfterOption,
        customTypeOption,
        categoryTypeOption,
      );
    },
    [
      start,
      typeOption,
      timeOption,
      sortOption,
      FILTERS_PARAMS.searchQuery,
      availableTypes,
      getData,
      notification,
      searchType.archived,
      searchType.drafts,
      statusOption,
      createdAfterOption,
      customTypeOption,
      categoryTypeOption,
    ],
  );

  useEffect(() => {
       updateCallback(
           start,
           sortOption,
           typeOption,
           timeOption,
           FILTERS_PARAMS.searchQuery,
           statusOption,
           createdAfterOption,
           customTypeOption,
           categoryTypeOption,
       );

       let timeout;
       let secs = 0;

       const updateData = () => {
         const {fetchMessages, setFetchMessages} = fetchDataObj;

         const tCount = fetchMessages && !displayBulkAction ? 0 : 15000;
         timeout = setTimeout(() => {
           if (secs >= 1800) {
             return () => {};
           }

           updateCallback(
               start,
               sortOption,
               typeOption,
               timeOption,
               FILTERS_PARAMS.searchQuery,
               statusOption,
               createdAfterOption,
               customTypeOption,
               categoryTypeOption,
           );

           secs += 15;

           if (!displayBulkAction) {
             updateData();
           }
           // eslint-disable-next-line no-unused-expressions
           setFetchMessages ? setFetchMessages(false) : null;
         }, tCount);
       }

       if (!displayBulkAction) {
         updateData();
       }

       return () => {
         if (timeout) {
           clearTimeout(timeout);
         }
       };
    // eslint-disable-next-line
  }, [start, sortOption, typeOption, timeOption, FILTERS_PARAMS.searchQuery, statusOption, createdAfterOption, customTypeOption, totalResults, fetchDataObj.fetchMessages, reloadDataToggle, categoryTypeOption, displayBulkAction]);

  const totalPages = (totalResults % itemsPerPage) === 0 ? totalResults
    / itemsPerPage : Math.floor(totalResults / itemsPerPage + 1);

  const endItem = (start / itemsPerPage + 1) * itemsPerPage > totalResults ? totalResults
    : start + itemsPerPage;

  const currPage = totalResults > itemsPerPage ? (start / itemsPerPage) + 1 : 1;
  const startItem = totalResults > itemsPerPage ? (start) + 1 : 1;

  const isFiltersEmpty = !Object.values(filterParams).some((val) => val && String(val).length > 0);

  /**
   * Delete or archive all the selected rows.
   *
   * @param {string} actionKey
   *
   */
  function handleSelectAction(actionKey) {
    const selectedRowsIds = rows
      .filter((row) => row.selected).map((row) => row.id);

    const { action } = selectActions ? selectActions
      .find(({ key }) => key === actionKey) : null;

    if (typeof action === 'function') {
      action(selectedRowsIds, updateCallback);
      displayBulkActionCallback(!displayBulkAction)
    }
  }

  const determineColumnClass = (columnName) => {
    //Used to standardize multiple word column names to align to css classes

    if (columnName === 'Delivery Details'){
      return 'table-td--Delivery-Details';
    }
    if (columnName === 'In Use'){
      return 'table-td--In-Use';
    }
    if (columnName === 'App Users'){
      return 'table-td-App-Users'
    }
    if (columnName === 'Type' && tableType === 'partnerManagement') {
      return 'table-td--Type-Partner'
    }
    if (columnName === 'Name' && tableType === 'partnerManagement') {
      return 'table-td--Name-Partner'
    }
    if (columnName === 'Geo Add-on') {
      return 'table-td--Geo-Add-on'
    }
    if (columnName === 'API Key') {
      return 'table-td--API-Key'
    }
    if (columnName === 'APNS Configured') {
      return 'table-td--APNS-Configured'
    }
    if (columnName === 'APNS Mode') {
      return 'table-td--APNS-Mode'
    }
    if (columnName === 'Launch Date') {
      return 'table-td--Launch-Date'
    }
    if (columnName === 'Org Id' && tableType === 'partnerManagement') {
      return 'table-td--Org-Id'
    }

    return `table-td--${columnName}`;
  }

  const displaySelectFilters = () => ENABLED_FILTERS.selectFilter && selectFilters !== null && (
    <Col className={`${styles.filter} ${styles['bulk-action-filter']}`} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Bulk Actions</Form.Label>
      <div className={styles['select-container']}>
        <CustomCheckbox value={displayBulkAction} onChange={handleBulkAction} />
          <DropdownButton
            className={styles.dropdown}
            as={ButtonGroup}
            title="Select"
            onSelect={(value) => handleSelectAllByFilter(value)}
            disabled={!displayBulkAction}
          >
            <Dropdown.Item eventKey="all">Select all</Dropdown.Item>
            <Dropdown.Item eventKey="none">Select none</Dropdown.Item>
            {
                  selectFilters.map((select) => (
                    <Dropdown.Item
                      key={select.key}
                      eventKey={select.key}
                    >
                      {select.text}
                    </Dropdown.Item>
                  ))
                }
          </DropdownButton>
          <DropdownButton
            className={styles.dropdown}
            as={ButtonGroup}
            title="Action"
            onSelect={(value) => handleSelectAction(value)}
            disabled={!displayBulkAction || !rows.find((row) => row.selected)}
          >
            {selectActions && selectActions.map(({ key, text }) => (
              <Dropdown.Item
                eventKey={key}
                key={key}
              >
                {text}
              </Dropdown.Item>
            ))}
          </DropdownButton>
      </div>
    </Col>
  );
  const typeFilters = () => ENABLED_FILTERS.typeFilter && FILTERS_PARAMS.typeFilter !== null && (
    <Col className={styles.filter} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Filter by Type</Form.Label>
      <DropdownButton
        className={styles.dropdown}
        as={ButtonGroup}
        title={typeOption.title}
        onSelect={(value) => handleChange('type', Number(value))}
        id='filter-by-type'
      >
        <Dropdown.Item eventKey="1">All</Dropdown.Item>
        <Dropdown.Item eventKey="2">Geolocation</Dropdown.Item>
        <Dropdown.Item eventKey="3">Time-based</Dropdown.Item>
      </DropdownButton>
    </Col>
  );
  const sortFilters = () => ENABLED_FILTERS.sortFilter && FILTERS_PARAMS.sortFilter !== null && (
    <Col className={styles.filter} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Sort</Form.Label>
      <DropdownButton
        className={styles.dropdown}
        as={ButtonGroup}
        title={sortOption.title}
        onSelect={(value) => handleChange('sort', Number(value))}
      >
        {filterAttrs.includes('created') && (
        <>
          <Dropdown.Item eventKey="1">Date Created-Descending</Dropdown.Item>
          <Dropdown.Item eventKey="2">Date Created-Ascending</Dropdown.Item>
        </>
        )}
        {filterAttrs.includes('name') && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="3">Name-Ascending</Dropdown.Item>
          <Dropdown.Item eventKey="4">Name-Descending</Dropdown.Item>
        </>
        )}
        {filterAttrs.includes('title') && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="5">Title-Ascending</Dropdown.Item>
          <Dropdown.Item eventKey="6">Title-Descending</Dropdown.Item>
        </>
        )}
        {filterAttrs.includes('dateTime') && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="7">Scheduled-Ascending</Dropdown.Item>
          <Dropdown.Item eventKey="8">Scheduled-Descending</Dropdown.Item>
        </>
        )}
      </DropdownButton>
    </Col>
  );
  const timeFilters = () => ENABLED_FILTERS.timeFilter && FILTERS_PARAMS.timeFilter !== null && (
    <Col className={styles.filter} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Filter by Created Date</Form.Label>
      <DropdownButton
        className={styles.dropdown}
        as={ButtonGroup}
        title={timeOption.title}
        onSelect={(value) => handleChange('time', Number(value))}
      >
        <Dropdown.Item eventKey="1">All times</Dropdown.Item>
        <Dropdown.Item eventKey="2">Last 24 hours</Dropdown.Item>
        <Dropdown.Item eventKey="3">Last 7 days</Dropdown.Item>
        <Dropdown.Item eventKey="4">Last 30 days</Dropdown.Item>
        <Dropdown.Item eventKey="5">Last 365 days</Dropdown.Item>
      </DropdownButton>
    </Col>
  );

  const segmentTypeFilters = () => ENABLED_FILTERS.segmentTypeFilter && FILTERS_PARAMS.segmentTypeFilter !== null && (
    <Col className={styles.filter} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Filter by Segment Type</Form.Label>
      <DropdownButton
        className={styles.dropdown}
        as={ButtonGroup}
        title={customTypeOption.title}
        onSelect={(value) => handleChange('filterCustomType', Number(value))}
      >
        <Dropdown.Item eventKey="1">All</Dropdown.Item>
        <Dropdown.Item eventKey="2">Static</Dropdown.Item>
        <Dropdown.Item eventKey="3">Dynamic</Dropdown.Item>
        {userHasDeepTarget ?
        <Dropdown.Item eventKey="6">DeepTarget</Dropdown.Item>
        : null}
        {userHasHubSpot ?
        <Dropdown.Item eventKey="5">Hubspot</Dropdown.Item>
        : null}
        {userHasTrellance ?
        <Dropdown.Item eventKey="4">Trellance</Dropdown.Item>
        : null}
      </DropdownButton>
    </Col>
  );

  const categoryTypeFilters = () => ENABLED_FILTERS.categoryTypeFilter && FILTERS_PARAMS.categoryTypeFilter !== null && (
  <Col className={styles['category-filter']} xs={12} sm={6} md={4} lg={3} xl={2}>
    <Form.Label className={styles.label}>Filter by Category</Form.Label>
    <DropdownButton
      className={styles.dropdown}
      as={ButtonGroup}
      title={categoryTypeOption? categoryTypeOption.label : ""}
      onSelect={(value) => handleCategoryChange('categoryTypeFilter', Number(value))}
    >
      {messageCategoryFilters.map(({ value, label }) => (
              <Dropdown.Item
                key={value}
                eventKey={value}
              >
                {label}
              </Dropdown.Item>
            ))}
    </DropdownButton>
  </Col>
);
  const getFilterStatusOptions = () => {
    const options = [];
    for (const [index, item] of FILTER_STATUS.entries()) {
      const key = index + 1;
      if (index === 1) options.push(<Dropdown.Divider key={{ key }} />);
      options.push(<Dropdown.Item eventKey={key} key={key}>{item.title}</Dropdown.Item>);
    }
    return options;
  };

  const statusFilters = () => ENABLED_FILTERS.statusFilter && (
    <Col className={styles.filter} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Filter by Status</Form.Label>
      <DropdownButton
        className={styles.dropdown}
        as={ButtonGroup}
        title={statusOption.title}
        onSelect={(value) => handleChange('filterStatus', Number(value))}
      >
        {getFilterStatusOptions()}
      </DropdownButton>
    </Col>
  );




  const createdAfterFilter = () => ENABLED_FILTERS.createdAfterFilter
    && FILTERS_PARAMS.createdAfterFilter !== null && (
    <Col className={styles.filter} xs={12} sm={6} md={4} lg={3} xl={2}>
      <Form.Label className={styles.label}>Date Created</Form.Label>
      <DropdownButton
        className={styles.dropdown}
        as={ButtonGroup}
        title={createdAfterOption.title}
        onSelect={(value) => handleChange('filterCreatedAfter', Number(value))}
      >
        <Dropdown.Item eventKey="1">All</Dropdown.Item>
        <Dropdown.Item eventKey="2">Last 7 days</Dropdown.Item>
        <Dropdown.Item eventKey="3">Last 30 days</Dropdown.Item>
        <Dropdown.Item eventKey="4">Last 90 days</Dropdown.Item>
        <Dropdown.Item eventKey="5">Last 365 days</Dropdown.Item>
      </DropdownButton>
    </Col>
  );

  return (
    <>
      <Card className={styles["table-view"]}>
        {isFilterable && (
            <>
              <Row className={styles['filters-container']}>
                <Col className={styles['col-filters']}>
                <Row>
              {typeFilters()}
              {timeFilters()}
              {statusFilters()}
              {categoryTypeFilters()}
              {segmentTypeFilters()}
              {createdAfterFilter()}
              {sortFilters()}
              {displaySelectFilters()}
                </Row>
                </Col>
                <Col className={styles['search-pagination-container']}>
                  <Form.Label className={styles['filter-label']}>Filter by keyword</Form.Label>
                <div className={styles.wrapper}>
                  <div className={styles['searchbar-container']}>
                    <Form.Control
                      className={styles.searchbar}
                      type="text"
                      placeholder="Type..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyPress={handleSearchByQuery}/>
                    <Button
                      variant="info"
                      size="sm"
                      onClick={handleApply}
                  >
                    Apply
                  </Button>
                  </div>
                  <Button
                      className={styles['clear-btn']}
                      variant="danger"
                      size="sm"
                      onClick={handleReset}
                      disabled={isFiltersEmpty}
                  >
                    Clear all
                  </Button>
                </div>
                  <Row className={styles['pagination-wrapper']}>
                    <div className={styles['pag-top-wrapper']}>
                  <Pagination
                      page={currPage}
                      totalPages={totalPages}
                      startItem={startItem}
                      endItem={endItem}
                      totalResults={totalResults}
                      onChange={handlePagination}/>
                </div>
                  </Row>
              </Col>
                </Row>
            </>
        )}
        <br></br>
        {isLoading && !loaded ? (
          <Spinner
            style={{ width: '4rem', height: '4rem', margin: '12px auto' }}
            animation="border"
            role="status"
          />
        ) : (
          <Table className={isCenteredCells ? `${styles['cells-centered']} ${styles.table}` : styles.table} striped hover>
            <thead className={styles.header}>
              <tr>
                {columns.map((name, idx) => (
                  // TODO: Temp fix
                  // eslint-disable-next-line react/no-array-index-key
                  <th className={styles[determineColumnClass(name)]} key={idx}>{name}</th>
                ))}
                 {columns.length > 0 ?  (
                    <th className={styles['table-td--Actions']}>Actions</th>) : null
                 }
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => customRow(row, handleSelectRow, updateCallback))}
            </tbody>
          </Table>
        )}
        {isPaginable && (
              <Pagination
                page={currPage}
                totalPages={totalPages}
                startItem={startItem}
                endItem={endItem}
                totalResults={totalResults}
                onChange={handlePagination}
              />
        )}
      </Card>
    </>
  );
}

CustomTable.propTypes = {
  getData: PropTypes.func.isRequired,
  customRow: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableTypes: PropTypes.arrayOf(PropTypes.string),
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  enabledFilters: PropTypes.arrayOf(PropTypes.string),
  filterAttrs: PropTypes.arrayOf(PropTypes.string),
  isFilterable: PropTypes.bool,
  isPaginable: PropTypes.bool,
  statusType: PropTypes.string,
  reloadDataToggle: PropTypes.bool,
  isCenteredCells: PropTypes.bool,
  selectActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  selectFilters: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ),
  messageCategoryFilters: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
  ),
  /** fetchMessages set to true will force a data reload. i.e. calling getData */
  fetchDataObj: PropTypes.shape({
    fetchMessages: PropTypes.bool,
    setFetchMessages: PropTypes.func,
  }),
};

CustomTable.defaultProps = {
  isFilterable: false,
  isPaginable: false,
  availableTypes: [],
  searchKeys: [],
  enabledFilters: [],
  filterAttrs: [],
  selectActions: [],
  selectFilters: [],
  messageCategoryFilters: [],
  fetchDataObj: {
    fetchMessages: false,
    setFetchMessages: null,
  },
  statusType: 'segments',
  reloadDataToggle: false,
  isCenteredCells: false,
  tableType: null,
};

export default CustomTable;
