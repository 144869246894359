import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styles from '../../../Campaigns/components/CampaignGallery/CampaignGallery.module.scss';
import adoptionOverviewStyles from './AdoptionOverview.module.scss';
import { formatNumber } from "../../../../Utils";

const AdoptionOverview = ({ notificationPermissionRate, totalAppUsers, totalPossibleRecipients }) => {
  const determineInsightDescription = () => {
  return (
    <>
      {totalAppUsers !== null && totalAppUsers !== undefined && (
        <p className={styles['label-value-container']}>
          <div className={styles['bold-label']}>App Users:</div>
          {formatNumber(totalAppUsers) || 'N/A'}
        </p>
      )}
      {notificationPermissionRate !== null && notificationPermissionRate !== undefined && (
        <p className={styles['label-value-container']}>
          <div className={styles['bold-label']}>Permission Rate:</div>
          {formatNumber(notificationPermissionRate) || 'N/A'} %
        </p>
      )}
      <p className={styles['label-value-container']}>
        <div className={styles['bold-label']}>Total Possible Recipients:</div>
        {formatNumber(totalPossibleRecipients) || 'N/A'}
      </p>
    </>
  );
};




  return (
    <article className={adoptionOverviewStyles['card-adoption-overview']}>
      <div className={styles['insight-card-content']}>
        <div className={styles['card-header']} style={{ textAlign: 'center' }}>
          <h4>
            <Row>
              <Col className={styles['insight-card-header-title']}>
                {'Adoption Overview'}
              </Col>
            </Row>
          </h4>
        </div>
        <div className={styles['card-body-nudge-today']}>
          {determineInsightDescription()}
        </div>
      </div>
    </article>
  );
};

AdoptionOverview.propTypes = {
  organizationId: PropTypes.string.isRequired, // Correct prop type
};

export default AdoptionOverview;
