import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import styles from '../CreateOrUpdateSegment/CreateOrUpdateSegment.module.scss';

function ImportSegmentFileSummary({ segmentSummary }) {
  const {
    totalEntries, uniqueEntries, matchedEntries, unmatchedEntries,
  } = segmentSummary;
  const totaEntriesText = () => {
    if (uniqueEntries === 1) {
      return `${uniqueEntries} unique entry`;
    }
    return `${uniqueEntries} unique entries`;
  };
  const matchedEntriesText = () => {
    if (matchedEntries === 1) {
      return `${matchedEntries} device found in the nudge system match entries in the file and will be included in your segment`;
    }
    if (matchedEntries > uniqueEntries) {
        return `${matchedEntries} devices found in the nudge system match entries in the file and will be included in your segment
                                  (One or more entries in the file match to more than one device in the system)`

    }

    return `${matchedEntries} devices found in the nudge system match entries in the file and will be included in your segment`;
  };
  const unmatchedEntriesText = () => {
    if (unmatchedEntries === 1) {
      return `${unmatchedEntries} entry don't match devices found in the nudge system and will be ignored`;
    }
    return `${unmatchedEntries} entries don't match devices found in the nudge system and will be ignored`;
  };

  return (
    <div className={styles.root}>
      <Row>
        <p>The file you selected contains the following:</p>
        <br />
        <ul>
          <li>{totaEntriesText()}</li>
          <li>{matchedEntriesText()}</li>
          <li>{unmatchedEntriesText()}</li>
        </ul>
      </Row>
    </div>
  );
}

ImportSegmentFileSummary.propTypes = {
  segmentSummary: PropTypes.instanceOf(Object),
};

ImportSegmentFileSummary.defaultProps = {
  segmentSummary: null,
};

export default ImportSegmentFileSummary;
