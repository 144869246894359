import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CHECK_ORG_KEY = gql`
  query CheckOrgKeyQuery($id: ID!) {
    checkOrgKey(id: $id) 
  }
`;

// organizations/1/devices?onlyTotal=true
export const GET_ORGANIZATION_DEVICES = gql`
  query GetOrganizationDevicesQuery(
    $id: ID!
    $params: String
    ) {
      getOrganizationDevices(
        id: $id
        params: $params
      ) {
        devices {
          id
          userId
          libraryVersion
          created
          updated
        }
        totalDevices
      }
  }
`;

// get message capping limit
export const GET_MESSAGE_CAPPING_LIMIT = gql`
  query GetMessageCappingLimitQuery($organizationId: Int!){
    getMessageCappingLimit(organizationId: $organizationId) {
      organizationId
      limitDay
      limitWeek
      limitMonth
      updatedBy
      updatedOn
    }
  }
`;

// get dictionary of feature flags associated by org
export const GET_ALL_FEATURE_FLAGS_WITH_USER_ID = gql`
  query GetAllFeatureFlagsWithUserIdQuery(
    $organizationId: Int!
    $id: String!
  ){
    getAllFeatureFlagsWithUserId(organizationId: $organizationId, id: $id){
        feature
        value
        tooltip
        tooltipUrl
    }
  }
`;
// get dictionary of given feature
export const GET_FEATURE_FLAG = gql`
  query GetFeatureFlagQuery(
    $organizationId: Int!
    $featureName: String
  ){
    getFeatureFlag(organizationId: $organizationId, featureName: $featureName){
      value
    }
  }
`;

// get information about current organization
export const GET_ORGANIZATION = gql`
  query GetOrganizationQuery($organizationId: Int!){
    getOrganization(organizationId: $organizationId){
      id
      created
      federationUrl
      larkyEmail
      larkyOrganizationId
      name
      apiKey
      featureTier
      nudgeGeo
      useSandbox
      isDeepLink
      organizationTypeId
      authKey
      teamId
      keyName
      keyId
      launchDate
      organizationType {
        active
        created
        displayName
        id
        isProduction
        name
        updated
      }
      organizationTypeId
      updated
      website
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query GetOrganizationsQuery($organizationId: Int!){
    getOrganizations(organizationId: $organizationId){
      hits {
          id
          name
          clientType
          userCount
          tier
          nudgeGeo
          apiKey
          organizationTypeId
          organizationType {
            active
            created
            displayName
            id
            isProduction
            name
            updated
          }
          apnsConfigure
          appUser
          featureTier
          launchDate
          useSandbox
       }
    }
  }
`;

export const GET_ORGANIZATION_CATEGORIES = gql`
    query GetOrganizationCategoriesQuery {
      getOrganizationCategories {
        categories {
            displayName
            id
        }
      }
    }    
`;

export const GET_MESSAGING_REACH = gql`
    query GetMessagingReachQuery($organizationId: Int!) {
      getMessagingReach(organizationId: $organizationId) {
        reach {
            lastWeekMembersMessaged
            lastWeekMembersMessagedPercentTrend
            lastWeekMembersMessagedTrendArrow
            lastWeekTotalImpressions
            lastWeekTotalImpressionsPercentTrend
            lastWeekTotalImpressionsTrendArrow
            lastWeekEngagements
            lastWeekEngagementsPercentTrend
            lastWeekEngagementsTrendArrow
            lastWeekEngagementsRatePercentTrend
            lastWeekEngagementsRateTrend
            lastWeekEngagementsRateTrendArrow
            lastMonthMembersMessaged
            lastMonthMembersMessagedPercentTrend
            lastMonthMembersMessagedTrendArrow
            lastMonthTotalImpressions
            lastMonthTotalImpressionsPercentTrend
            lastMonthTotalImpressionsTrendArrow
            lastMonthEngagements
            lastMonthEngagementsPercentTrend
            lastMonthEngagementsTrendArrow
            lastMonthEngagementsRatePercentTrend
            lastMonthEngagementsRateTrend
            lastMonthEngagementsRateTrendArrow
            last90dayMembersMessaged
            last90dayMembersMessagedPercentTrend
            last90dayMembersMessagedTrendArrow
            last90dayTotalImpressions
            last90dayTotalImpressionsPercentTrend
            last90dayTotalImpressionsTrendArrow
            last90dayEngagements
            last90dayEngagementsPercentTrend
            last90dayEngagementsTrendArrow
            last90dayEngagementsRatePercentTrend
            last90dayEngagementsRateTrend
            last90dayEngagementsRateTrendArrow
            locationPermissionRate
            locationPermissionRateTrend
            locationPermissionRateTrendArrow
            notificationPermissionRate
            notificationPermissionRateTrend
            notificationPermissionRateTrendArrow
        }
      }
    }    
`;

export const GET_ORGANIZATION_CATEGORY_DATA = gql`
  query getOrganizationCategoryData($organizationId: Int!) {
    getOrganizationCategoryData(organizationId: $organizationId) {
      categories {
        categoryName
        categoryPercentValue
      }
    }
  }
`;

export const GET_ORGANIZATION_INSIGHTS_DATA = gql`
  query getOrganizationInsightsData($organizationId: Int!) {
    getOrganizationInsightsData(organizationId: $organizationId) {
      insights {
        title
        description
        url
        icon
        membersMessaged
        engagements
        totalImpressions
        activeCampaigns
        scheduledAlertNudges
        activeLocationNudges
        ranking
      }
    }
  }
`;

export const GET_ADOPTION_OVERVIEW_DATA = gql`
    query GetAdoptionOverviewDataQuery($organizationId: Int!) {
      getAdoptionOverviewData(organizationId: $organizationId) {
        data {
            overallPermissionRate
              totalAppUsers
              totalPossibleRecipients
        }
      }
    }    
`;


export const GET_ORGANIZATION_PROFILE_PICTURE = gql`
  query getOrganizationProfilePicture($organizationId: Int!) {
    getOrganizationProfilePicture(organizationId: $organizationId) {
      url
    }
  }
`;
