// Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Styles
import 'react-widgets/dist/css/react-widgets.css';
import styles from './Home.module.scss';

// Components
import { Col, Container, Row } from 'react-bootstrap';
import Larky from 'components/Larky';
import PieChart from '../Home/components/PieChart/PieChart'
import MessagingReach from "./components/MessagingReach/MessagingReach";
import InsightsCard from "./components/Insights/InsightsCard";
import InsightsLoadingCard from "./components/Insights/InsightsLoadingCard";
import AdoptionOverview from "./components/AdoptionOverview/AdoptionOverview";
import OrganizationService from "../../services/OrganizationService";

// GraphQL
import {
  GET_ALL_FEATURE_FLAGS_WITH_USER_ID,
  GET_MESSAGING_REACH,
  GET_ORGANIZATION_CATEGORY_DATA,
  GET_ORGANIZATION_INSIGHTS_DATA,
  GET_ADOPTION_OVERVIEW_DATA
} from '../../graphql/queries/organizations';

import {
  SEARCH_NUDGES,
  GET_NUDGES_RECEIVED,
  GET_NUDGES_TAPPED,
  GET_NUDGES_HEADERS,
} from 'graphql/queries/nudges';

// Constants
import { ROUTES } from 'Constants';
import { NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD } from '../../Constants';

// Context
import {formatNumber, getUser, getUserFeature} from '../../Utils';
import { downloadAnalyticsDB } from '../../DashboardAnalyticsUtils';
import UserService from '../../services/UserService';
import { useAuthMachineValue } from '../../contexts/auth-machine.context.ts';
import { useNotification } from 'components/Notification/Notification';


const Home = () => {
  const history = useHistory();
  const notification = useNotification();

  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const getMessagingReachQuery = useQuery(GET_MESSAGING_REACH, { skip: true });
  const getOrganizationCategoryDataQuery = useQuery(GET_ORGANIZATION_CATEGORY_DATA, {skip: true});
  const getOrganizationInsightsDataQuery = useQuery(GET_ORGANIZATION_INSIGHTS_DATA, {skip: true});
  const getNudgesReceivedQuery = useQuery(GET_NUDGES_RECEIVED, { skip: true });
  const getNudgesTappedQuery = useQuery(GET_NUDGES_TAPPED, { skip: true });
  const getNudgesHeadersQuery = useQuery(GET_NUDGES_HEADERS, { skip: true });
  const getAdoptionOverviewDataQuery = useQuery(GET_ADOPTION_OVERVIEW_DATA,{ skip: true })

  const [messagingReachData, setMessagingReachData] = useState([]);
  const [organizationCategoryData, setOrganizationCategoryData] = useState({});
  const [adoptionOverviewData, setAdoptionOverviewData] = useState([]);
  const [insightCardData, setInsightCardData] = useState([]);
  const [userHasNudges, setUserHasNudges] = useState(false);
  const [userHasExportAnalytics, setUserHasExportAnalytics] = useState(false);
  const [exportAnalyticsToolTipText, setExportAnalyticsToolTipText] = useState(false);
  const [exportAnalyticsUrl, setExportAnalyticsUrl] = useState(null);
  const [pieChartLoading, setPieChartLoading] = useState(false);
  const [insightCardLoading, setInsightCardLoading] = useState(false);
  const [messagingReachLoading, setMessagingReachLoading] = useState(false);
  const [exportAnalyticsLoading, setexportAnalyticsLoading] = useState(false);
  const [userHasLocationsRead, setUserHasLocationsRead] = useState(false);
  const user = getUser();
  const [current] = useAuthMachineValue();
  const organizationId = user && user.currentOrganization ? user.currentOrganization.id : null;
  const ENVIRONMENT = current.context.user.environment;
  const FILE_NAME = 'Home';

  useEffect(async () => {
    const hasNudgeFeatureGeo = await getUserFeature('geolocations', 2);
    const hasNudgeFeatureGeoRead = await getUserFeature('geolocations');
    const hasNudgeFeatureTime = await getUserFeature('nudges_timed', 2);
    const hasExportAnalytics = await getUserFeature('export_analytics');
    await setUserHasNudges(hasNudgeFeatureTime || hasNudgeFeatureGeo);
    if (hasNudgeFeatureGeoRead > 0) {
      await setUserHasLocationsRead(hasNudgeFeatureGeoRead);
    }

    await setUserHasExportAnalytics(!!hasExportAnalytics);
  }, [user.featureFlags]);

  useEffect(async () => {
    await setMessagingReachLoading(true)
    const messagingReachDataResponse = await OrganizationService.getMessagingReach(getMessagingReachQuery, organizationId);
    await setMessagingReachData(messagingReachDataResponse);
    await setMessagingReachLoading(false)
  }, [user.id]);

  // Fetch Adoption Overview Data
  useEffect(() => {
    const fetchAdoptionOverviewData = async () => {
      const adoptionOverviewResponse = await OrganizationService.getAdoptionOverviewData(getAdoptionOverviewDataQuery, organizationId);
      setAdoptionOverviewData(adoptionOverviewResponse.data[0]); // Assuming the data is in the first element
    };

    if (organizationId) {
      fetchAdoptionOverviewData();
    }
  }, [organizationId]);



  useEffect(async () => {
    setPieChartLoading(true)
    const organizationCategoryDataResponse = await OrganizationService.getOrganizationCategoryData(getOrganizationCategoryDataQuery, organizationId)
    await setOrganizationCategoryData(organizationCategoryDataResponse)
    await setPieChartLoading(false)
  }, [user.id])

  useEffect(async () => {
    setInsightCardLoading(true)
    const insightCardDataResponse = await OrganizationService.getOrganizationInsightsData(getOrganizationInsightsDataQuery, organizationId)
    await setInsightCardData(insightCardDataResponse)
    await setInsightCardLoading(false)
  }, [user.id])

  const downloadAnalyticsParams = {
    ENVIRONMENT,
    FILE_NAME,
    organizationId,
    notification,
    getNudgesReceivedQuery,
    getNudgesTappedQuery,
    getNudgesHeadersQuery,
    NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD,
  };

  useEffect(async () => {
    const exportAnalyticsFeatureInfo = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'export_analytics');
    setExportAnalyticsToolTipText(exportAnalyticsFeatureInfo.tooltip);
    setExportAnalyticsUrl(exportAnalyticsFeatureInfo.tooltipUrl);
  }, [user.id]);

  async function onExportAnalyticsClick() {
    setexportAnalyticsLoading(true);
    if (userHasExportAnalytics) {
    await downloadAnalyticsDB(downloadAnalyticsParams);
  } else {
    window.open(exportAnalyticsUrl, '_blank');
  }
  setexportAnalyticsLoading(false);
  }


  const createToolTipText = (textBody, url) => `${exportAnalyticsToolTipText} Click to learn more!`;
  const insightCardRender = (cardData) => {
    if (insightCardLoading) {
      return (
          <>
            <InsightsLoadingCard key={1}/>
            <InsightsLoadingCard key={2}/>
            <InsightsLoadingCard key={3}/>
            <InsightsLoadingCard key={4}/>
          </>
      )
    }

    return cardData.map((data) => {
      return (
          <InsightsCard
              key={data.title}
              title={data.title}
              description={data.description}
              iconName={data.icon}
              onClick={ data.url ? () => {
                // This decides behavior to push to an external link or use react router for an portal url
                data.url[0] !== '/' ? window.open(data.url, '_blank') : history.push(data.url)
              } : () => null}
              type={data.ranking}
              totalImpressions={data.totalImpressions}
              membersMessaged={data.membersMessaged}
              engagements={data.engagements}
              activeCampaigns={data.activeCampaigns}
              scheduledAlertNudges={data.scheduledAlertNudges}
              activeLocationNudges={data.activeLocationNudges}
              userHasLocationsRead={userHasLocationsRead}
          />
      )
    })
  }

  const messagingReachHeaders = [
    {title: '', icon: null},
    {title: 'Unique Recipients', icon: null},
    {title: 'Total Impressions', icon: null},
    {title: 'Engagements', icon: null},
    {title: 'Engagement Rate', icon: null},
  ]

  return (
    <Container fluid>
      <Row>
        <Col className={`col-12 ${styles['p3-no-top']}`}>
          <Container fluid>
            <Row>
              <Col className={styles['padding-none']}>
                <Container fluid>
                  <Row className="justify-content-space-between">
                    <Col>
                      <h1>Dashboard</h1>
                      <Row className={styles['subHeader-row']}>
                        <h4>
                          Welcome to your nudge dashboard. Choose an option above to get started.
                        </h4>
                        <div className={styles['subHeader-buttons']}>
                          {userHasNudges ? (
                            <Larky.Button
                              className={styles['no-left-margin-button']}
                              onClick={() => history.push(ROUTES.NUDGES.CREATE)}
                            >
                              CREATE AN ALERT NUDGE
                            </Larky.Button>
                          ) : null}
                          <Larky.Button
                            className={!userHasExportAnalytics ? styles['no-left-margin-button-disabled'] : ['no-left-margin-button']}
                            onClick={() => onExportAnalyticsClick()}
                            data-tip={!userHasExportAnalytics ? createToolTipText() : null}
                            isLoading={exportAnalyticsLoading}
                          >
                            EXPORT ANALYTICS
                          </Larky.Button>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
              <div className={`card ${styles.card}`}>
                <div className={styles['card-wrapper']}>
                  <Col>
                      <h1 className={styles['header-title']}>Messaging Reach</h1>
                    <Row className={styles['row-border']}>
                  <Col className={styles['overflow-col']}>
                    <MessagingReach
                      tableData={messagingReachData}
                      isLoading={messagingReachLoading}
                      exportAnalyticsLoading={false}
                      tableHeaders={messagingReachHeaders}
                      hasAnalytics={userHasExportAnalytics}
                      trendToolTipMessage={createToolTipText()}
                      trendToolTipUrl={exportAnalyticsUrl}
                    />
                  </Col>
                 </Row>
                      <h1 className={styles['header-title']}>Insights</h1>
                    <Row>
                  <Col className={styles['pie-col']}>
                    <PieChart
                      loading={pieChartLoading}
                      chartData={organizationCategoryData}
                    />
                    {adoptionOverviewData.overallPermissionRate || adoptionOverviewData.totalAppUsers || adoptionOverviewData.totalPossibleRecipients ? (
                        <AdoptionOverview
                          notificationPermissionRate={adoptionOverviewData.overallPermissionRate}
                          totalAppUsers={adoptionOverviewData.totalAppUsers}
                          totalPossibleRecipients={adoptionOverviewData.totalPossibleRecipients}
                        />
                    ):null }

                  </Col>

                  <Col className={styles['seventy-percent-col']}>
                    <Row className={styles['centered-col']}>
                      {insightCardRender(insightCardData)}
                    </Row>
                  </Col>
                 </Row>
                </Col>
                </div>
              </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

Home.propTypes = {
  organizationId: PropTypes.number,
};

Home.defaultProps = {
  organizationId: null,
};

export default Home;
