// Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Redirect, Route, Switch, useHistory, useParams,
} from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

// Components
import CustomTable from 'components/CustomTable/CustomTable';
import PageHeader from 'components/PageHeader/PageHeader';
import { useNotification } from 'components/Notification/Notification';

// Services
import NudgeService from 'services/NudgeService';
import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';

// GraphQL
import { ARCHIVE_NUDGE_MUTATION, DELETE_NUDGE_MUTATION, CLONE_NUDGE_MUTATION } from 'graphql/mutations/nudges';

import {
  SEARCH_NUDGES,
  GET_NUDGE,
  GET_NUDGES_RECEIVED,
  GET_NUDGES_TAPPED,
} from 'graphql/queries/nudges';

// Utils
import {
  archiveManyRows, archiveRow, deleteManyRows, deleteRow, getUserFeature, cloneRow,
} from 'Utils';

// Constants
import { ROUTES } from 'Constants';
import { GET_NUDGES_HEADERS, GET_NUDGE_AND_CAMPAIGN_CATEGORIES } from '../../graphql/queries/nudges';

// Local components
import NudgeRow from './components/NudgeRow/NudgeRow';
import AnalyticsModal from './components/AnalyticsModal/AnalyticsModal';

// Styles
import styles from './Nudges.module.scss';
import { useInitialFocus } from '../../hooks/useInitialFocus';
import Analytics from '../../libs/Analytics';
import { NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD } from '../../Constants';

function LocationNudges({ organizationId }) {
  const notification = useNotification();
  const history = useHistory();
  useInitialFocus();

  const [showModal, setShowModal] = useState(false);
  const [currentNudge, setCurrentNudge] = useState(null);
  const [archiveNudgeMutation] = useMutation(ARCHIVE_NUDGE_MUTATION);
  const [deleteNudgeMutation] = useMutation(DELETE_NUDGE_MUTATION);
  const [cloneNudgeMutation] = useMutation(CLONE_NUDGE_MUTATION);
  const searchNudgesQuery = useQuery(SEARCH_NUDGES, { skip: true });
  const getNudgeMessageCategories = useQuery(GET_NUDGE_AND_CAMPAIGN_CATEGORIES, { skip: true })
  const [displayBulkAction, setDisplayBulkAction] = useState(false);

  const [hasNudgeEditPermission, setHasNudgeEditPermission] = useState(false);
  const [messageCategoryOptions, setMessageCategoryOptions] = useState([]);

  const getData = async (orderBy, sortOrder, start, params) => {
    Object.assign(params, { organizationId });
    Object.assign(params, {type: "Geolocation"})
    return NudgeService.all(searchNudgesQuery, orderBy, sortOrder, start, params);
  };


  // Collect message Categories
  useEffect(async() => {
    const getMessageCategories = async () => {
      try {
        const nudgeCategoryResponse = await NudgeService.getNudgeAndCampaignCategories(getNudgeMessageCategories);
        const filterOptions = {value: 0, label: "All"}
        nudgeCategoryResponse.splice(0, 0, filterOptions)
        setMessageCategoryOptions(nudgeCategoryResponse)
      } catch (e) {
        console.log(e.message);
      }
    }
    await getMessageCategories()
  }, [])

  useEffect(async () => {
    const userHasNudgeGeoEditPermission = await getUserFeature('geolocations', 2);
    // const userHasNudgeTimeEditPermission = await getUserFeature('nudges_timed', 2);
    // setHasNudgeEditPermission(userHasNudgeGeoEditPermission || userHasNudgeTimeEditPermission);
    setHasNudgeEditPermission(userHasNudgeGeoEditPermission);
  }, []);

  const currentUrl = window.location.pathname + window.location.search;

  const routes = [
    {
      path: ROUTES.LOCATIONNUDGES.MAIN,
      title: 'My location nudges',
      subtitle: 'View all of your location nudges.',
    },
    {
      path: ROUTES.LOCATIONNUDGES.DRAFTS,
      title: 'My locattion nudge drafts',
      subtitle: 'View all of your location nudge drafts.',
    },
    {
      path: ROUTES.LOCATIONNUDGES.ARCHIVED,
      title: 'My location archived nudges',
      subtitle: 'View all of your location archived nudges.',
    },
  ];

  const selectActions = [
    {
      key: 'delete_selected',
      text: 'Delete selected',
      action: (data, updateCallback) => {
        deleteManyRows(NudgeService,
          notification,
          deleteNudgeMutation,
          data,
          updateCallback,
          'Nudges');
      },
    },

  ];

  if (currentUrl === ROUTES.LOCATIONNUDGES.MAIN) {
    selectActions.push({
      key: 'archive_selected',
      text: 'Archive selected',
      action: (data, updateCallback) => {
        archiveManyRows(NudgeService,
          notification,
          archiveNudgeMutation,
          data,
          updateCallback,
          'Nudges');
      },
    });

    selectActions.push({
      key: 'unarchive_selected',
      text: 'Unarchive selected',
      action: (data, updateCallback) => {
        archiveManyRows(NudgeService,
          notification,
          archiveNudgeMutation,
          data,
          updateCallback,
          'Nudges',
          false);
      },

    });
  }

  if (currentUrl === ROUTES.LOCATIONNUDGES.ARCHIVED) {
    selectActions.push({
      key: 'unarchive_selected',
      text: 'Unarchive selected',
      action: (data, updateCallback) => {
        archiveManyRows(NudgeService,
          notification,
          archiveNudgeMutation,
          data,
          updateCallback,
          'Nudges',
          false);
      },
    });
  }

  if(currentUrl.includes('/locationnudges/main?filterStatus=2') || currentUrl.includes('/locationnudges/main?filterStatus=3') || currentUrl.includes('/locationnudges/main?filterStatus=4')) {

    selectActions.push({
      key: 'archive_selected',
      text: 'Archive selected',
      action: (data, updateCallback) => {
        archiveManyRows(NudgeService,
            notification,
            archiveNudgeMutation,
            data,
            updateCallback,
            'Nudges');
      },
    });
  }

  const setHandleArchiveRow = (path, data, updateCallback) => {
    if (path === ROUTES.LOCATIONNUDGES.DRAFTS) return null;
    return () => archiveRow(
      NudgeService,
      notification,
      archiveNudgeMutation,
      data,
      updateCallback,
      'Nudge',
    );
  };

  const cloneRow = async (mutation, id) => {
    const newActionId = await NudgeService.clone(mutation, id);
    if (newActionId) {
      history.push(`${ROUTES.LOCATIONNUDGES.EDIT}/${newActionId.actionId}`);
    }
  };

  // menu item modal selection logic
  const [currentAuth] = useAuthMachineValue();
  const ENVIRONMENT = currentAuth.context.user.environment;
  const FILE_NAME = currentNudge ? `Nudge_Analytics_${currentNudge.id}.jsx` : 'Nudge_Analytics.jsx';

  const getNudgeQuery = useQuery(GET_NUDGE, { skip: true });

  useEffect(async () => {
    const nudgeDetails = currentNudge ? await NudgeService.getNudge(getNudgeQuery, currentNudge.id) : null;
    setCurrentNudge(nudgeDetails);
  }, []);

  const hideModalCallback = () => {
    setShowModal(false);
  };

  const determineNudgeColumns = () => {
    const nudgeColumns = ['Status','Category', 'Name', 'Created', 'Message', 'Delivery Details']
    if (hasNudgeEditPermission && displayBulkAction) {
      nudgeColumns.splice(0, 0, 'Select')
    }
    return nudgeColumns
  }

  const determineNudgeFilters = () => {
    const nudgeFilters = ['statusFilter','sortFilter', 'searchQuery', 'categoryTypeFilter']
    if (hasNudgeEditPermission) {
      nudgeFilters.splice(0, 0, 'selectFilter')
    }
    return nudgeFilters
  }

  const getNudgesReceivedQuery = useQuery(GET_NUDGES_RECEIVED, { skip: true });
  const getNudgesTappedQuery = useQuery(GET_NUDGES_TAPPED, { skip: true });
  const getNudgesHeadersQuery = useQuery(GET_NUDGES_HEADERS, { skip: true });

  const currentNudgeMsg = currentNudge ? currentNudge.message : null;

  const getAnalyticsModalParams = () => {
    const downloadAnalyticsParams = {
      ENVIRONMENT,
      FILE_NAME,
      organizationId,
      notification,
      getNudgesReceivedQuery,
      getNudgesTappedQuery,
      getNudgesHeadersQuery,
      NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD,
    };
    if (currentNudge) {
      return {
        id: currentNudge.messageId ? currentNudge.messageId : '', name: currentNudgeMsg.name, analyticsParams: downloadAnalyticsParams, type: 'Nudge',
      };
    }
    return { id: '', name: '' };
  };

  return (
    <Switch>
      {routes.map(({
        path,
        title,
        subtitle,
      }) => (
        <Route key={path} path={path}>
          <div className={styles.root}>
            <AnalyticsModal
              organizationId={organizationId}
              nudgeId={getAnalyticsModalParams().id}
              downloadAnalyticsParams={getAnalyticsModalParams()}
              show={showModal}
              callback={hideModalCallback}
              title="Nudge Analytics"
              text={currentNudge ? currentNudge.title : null}
            />
            <PageHeader
              title={title}
              subtitle={subtitle}
              hasEditPermission={hasNudgeEditPermission}
            />
            <CustomTable
              getData={getData}
              messageCategoryFilters={messageCategoryOptions}
              customRow={(data, handleSelect, updateCallback) => (
                <NudgeRow
                  key={data.id}
                  data={data}
                  handleSelect={handleSelect}
                  handleArchiveRow={setHandleArchiveRow(path, data, updateCallback)}
                  handleDeleteRow={() => deleteRow(NudgeService,
                    notification,
                    deleteNudgeMutation,
                    data.id,
                    updateCallback,
                    'Nudge')}
                  handleCloneRow={() => cloneRow(cloneNudgeMutation, data.id)}
                  handleShowAnalytics={() => {
                    // set the parent selected nudge data coming from data.id
                    setCurrentNudge(data);
                    setShowModal(true);
                  }}
                  isSelectable={displayBulkAction}
                  hasEditPermission={hasNudgeEditPermission}
                />
              )}
              displayBulkActionCallback={setDisplayBulkAction}
              displayBulkAction={displayBulkAction}
              // availableTypes={['drafts', 'archived']}
              columns={determineNudgeColumns()}
              searchKeys={['q', 'sort', 'filterStatus', 'category_key']}
              enabledFilters={determineNudgeFilters()}
              filterAttrs={['created', 'name', 'title']}
              selectActions={selectActions}
              statusType="locationnudges"
              selectFilters={[
                {
                  text: 'Select all sent',
                  key: 'sent',
                },
              ]}
              isFilterable
              isPaginable
            />
          </div>
        </Route>
      ))}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

LocationNudges.propTypes = {
  organizationId: PropTypes.number,
};

LocationNudges.defaultProps = {
  organizationId: null,
};

export default LocationNudges;
