// Dependencies
import moment from 'moment';

// Constants
import { CONDITION_TYPES } from 'Constants';

// export const isLocationNudgeExpired = (conditions) => {
//   if (conditions) {
//     const { dateTimeRangeEnd } = conditions;
//     console.log(dateTimeRangeEnd)
//     return dateTimeRangeEnd == null ? false : moment().isAfter(dateTimeRangeEnd);
//   }
//   return false;
// };

export const isLocationNudgeExpired = (conditions) => {
  if (conditions) {
    const { dateTimeRangeEnd } = conditions;

    // Ensure dateTimeRangeEnd is not null or undefined
    if (dateTimeRangeEnd == null) {
      return false;
    }

    // Compare only the date (ignoring time) by setting both to the start of the day
    const endDate = moment(dateTimeRangeEnd).startOf('day');
    const currentDate = moment().startOf('day');

    return currentDate.isAfter(endDate); // Check if the current date is after the end date
  }

  return false;
};

export const isTimeBasedNudgeExpired = (conditions) => {
  let dateTime = null;
  if (conditions) {
    conditions.forEach((condition) => {
      if (condition.conditionType.id === CONDITION_TYPES.SCHEDULED_ACTION.ID) {
        dateTime = condition.dateTime;
      }
    });
    return moment().isAfter(dateTime);
  }
  return false;
};

export const getTime = (dateTime, timeHourValue, timeMinuteValue, isWithIncrement = false) => {
  /*
 * This function takes the following parameters:
 * dateTime: dateTime object
 * timeHourValue: string that can be cast to number
 * timeMinuteValue: string that can be cast to number
 * isWithIncrement: string that can be cast to number
 *
 * It returns a formatted dateTime using the given params
 */
  if (isWithIncrement) {
    return moment(dateTime)
      .set('hour', Number(timeHourValue) + 12)
      .set('minute', Number(timeMinuteValue));
  }
  return moment(dateTime)
    .set('hour', Number(timeHourValue))
    .set('minute', Number(timeMinuteValue));
};

export const determineTimeChange = (oldTime, newTime, dateTime, timeHourValue, timeMinuteValue) => {
  /*
 * This function takes the following parameters:
 * oldTime: dateTime object
 * newTime: dateTime object
 * dateTime: dateTime object
 * timeHourValue: string that can be cast to number
 * timeMinuteValue: string that can be cast to number
 *
 * It returns a formatted dateTime using the given params
 */
  let result = null;
  if ((oldTime !== newTime) && (newTime.includes('PM'))) {
    // account for updating from AM to PM
    result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
  } else if (oldTime !== newTime) {
    // if time changed in same zone (AM or PM)
    result = getTime(dateTime, timeHourValue, timeMinuteValue);
    // time unchanged in PM
  } else if ((oldTime == newTime) && newTime.includes('PM')) {
    result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
  } else if (newTime) {
    result = getTime(dateTime, timeHourValue, timeMinuteValue);
  }
  return result;
};

export const getDateTimeObject = (timeTypeValue, dateTime, timeHourValue, timeMinuteValue) => {
  /*
 * This function takes the following parameters:
 * timeTypeValue: string
 * dateTime: dateTime object
 * timeHourValue: string that can be cast to number
 * timeMinuteValue: string that can be cast to number
 *
 * It returns a formatted dateTime using the given params
 */
  let result = null;
  if (timeTypeValue === 'P') {
    result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
  } else {
    result = getTime(dateTime, timeHourValue, timeMinuteValue);
  }
  return result;
};

export const getDateTimeObjectWithUpdate = (
  isUpdate, oldTime, timeTypeLabel,
  timeTypeValue, dateTime, timeHourValue, timeMinuteValue,
) => {
  /*
 * This function takes the following parameters:
 * isUpdate: boolean
 * oldTime: dateTime object
 * timeTypeLabel: string
 * timeTypeValue: string
 * dateTime: dateTime object
 * timeHourValue: string that can be cast to number
 * timeMinuteValue: string that can be cast to number
 *
 * It returns a formatted dateTime using the given params
 */
  let result = dateTime;
  if (isUpdate) {
    const newTime = `${timeHourValue}:${timeMinuteValue} ${timeTypeLabel}`;
    result = determineTimeChange(oldTime, newTime, dateTime, timeHourValue, timeMinuteValue);
  }
  if (!isUpdate) {
    result = getDateTimeObject(timeTypeValue, dateTime, timeHourValue, timeMinuteValue);
  }
  return result;
};
