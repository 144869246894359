/**
 * CampaignGallery.jsx
 *
 * Component that displays Campaigns that are predefined for clients.
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

// styles
import styles from '../../../Campaigns/components/CampaignGallery/CampaignGallery.module.scss';
import specificCardStyles from './InsightsCard.module.scss'

// components
import {Col, Row} from 'react-bootstrap';

// icons
import checkmarkIcon from 'assets/img/checkmark-icon.png';
import starIcon from 'assets/img/star-icon.png';
import forwardIcon from 'assets/img/forward-link.png';
import trendDownALot from 'assets/img/trend_down_a_lot.png';
import warningIcon from 'assets/img/warning-icon.png'
import toolTipIcon from "assets/img/tooltipIcon.png";
import calendarIcon from "assets/img/calendar-icon.png"

//constants
import { formatNumber } from 'Utils';

const InsightsCard = ({
                          type,
                          title,
                          description,
                          onClick,
                          iconName,
                          membersMessaged,
                          totalImpressions,
                          engagements,
                          activeCampaigns,
                          scheduledAlertNudges,
                          activeLocationNudges,
                          userHasLocationsRead
                      }) => {

    const determineIcon = (icon) => {
        if (icon === "green_check_icon") {
            return (<img className={styles['insight-icon']} src={checkmarkIcon}/>)
        }
        if (icon === 'orange_warning_icon') {
            return (<img className={styles['insight-icon']} src={warningIcon}/>)
        }
        if (icon === 'blue_help_icon') {
            return (<img className={styles['insight-icon']} src={toolTipIcon}/>)
        }
        if (icon === 'calendar_icon') {
            return (<img className={styles['insight-icon']} src={calendarIcon}/>)
        }
        if (icon === 'star_icon') {
            return (<img className={styles['insight-icon']} src={starIcon}/>)
        }
        if (icon === 'forward-link') {
            return (<img className={styles['insight-icon']} src={forwardIcon}/>)
        }
        return null;
    }
    const determineInsightDescription = () => {
        if (type === 1 && membersMessaged) {
            return (
                <>
                    <p className={styles['label-value-container']}>
                        <div className={styles['bold-label']}>Members Messaged:</div>
                        {formatNumber(membersMessaged)}
                    </p>
                    <p className={styles['label-value-container']}>
                        <div className={styles['bold-label']}>Total Impressions:</div>
                        {formatNumber(totalImpressions)}
                    </p>
                    <p className={styles['label-value-container']}>
                        <div className={styles['bold-label']}>Engagements:</div>
                        {formatNumber(engagements)}
                    </p>
                </>
            );
        }

        if (type === 99) {
            return (
              <>
                <p className={styles['label-value-container']}>
                  <div className={styles['bold-label']}>Active Campaigns:</div>
                  {formatNumber(activeCampaigns)}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();  // Prevent the default behavior of anchor tag
                      window.location.href = '/campaigns/main?filterStatus=3'; // Open in the same window
                    }}
                    style={{ fontWeight: 'bold', marginLeft: '4px', marginTop: '-1px' }}
                  >
                    {determineIcon('forward-link')}
                  </a>
                </p>

                {(userHasLocationsRead) ? (
                <p className={styles['label-value-container']}>
                    <div className={styles['bold-label']}>Active Location Nudges:</div>
                    {formatNumber(activeLocationNudges)}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default behavior of anchor tag
                            window.location.href = 'locationnudges/main?filterStatus=3'; // Open in the same window
                        }}
                        style={{ fontWeight: 'bold', marginLeft: '4px', marginTop: '-1px' }}
                    >
                        {determineIcon('forward-link')}
                    </a>
                </p>
            ):null}

                <p className={styles['label-value-container']}>
                  <div className={styles['bold-label']}>Upcoming Alert Nudges:</div>
                  {formatNumber(scheduledAlertNudges)}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();  // Prevent the default behavior of anchor tag
                      window.location.href = '/nudges/main?filterStatus=2'; // Open in the same window
                    }}
                    style={{ fontWeight: 'bold', marginLeft: '4px', marginTop: '-1px' }}
                  >
                    {determineIcon('forward-link')}
                  </a>
                </p>
              </>
            );

        }

        // Default description if neither type 1 nor type 2
        return <p>{description}</p>;
    };

    return type === 1 ? (
    <>
        <article className={styles['card-insight-today']} onClick={() => onClick()}>
            <div className={styles['insight-card-content']}>
                <div className={styles['card-header']}>
                    <h4>
                        <Row>
                            <Col className={styles['card-header-arrow']}>
                                {determineIcon(iconName)}
                            </Col>
                            <Col className={styles['insight-card-header-title']}>
                                {title}
                            </Col>
                        </Row>
                    </h4>
                </div>
                <div className={styles['card-body-nudge-today']}>
                    {determineInsightDescription()}
                </div>
            </div>
        </article>
    </>
) : type === 99 ? (
    <>
        <article className={styles['card-insight-today']} onClick={() => onClick()}>
            <div className={styles['insight-card-content']}>
                <div className={styles['card-header']}>
                    <h4>
                        <Row>
                            <Col className={styles['card-header-arrow']}>
                                {determineIcon('star_icon')}
                            </Col>
                            <Col className={styles['insight-card-header-title']}>
                                {title}
                            </Col>
                        </Row>
                    </h4>
                </div>
                <div className={styles['card-body-nudge-today']}>
                    {determineInsightDescription()}
                </div>
            </div>
        </article>
    </>
) : (
    <>
        <article className={styles['card-insight']} onClick={() => onClick()}>
            <div className={styles['insight-card-content']}>
                <div className={styles['card-header']}>
                    <h4>
                        <Row>
                            <Col className={styles['card-header-arrow']}>
                                {determineIcon(iconName)}
                            </Col>
                            <Col className={styles['insight-card-header-title']}>
                                {title}
                            </Col>
                        </Row>
                    </h4>
                </div>
                <div className={styles['card-body-insight']}>
                    <p>{description} <a style={{ fontWeight: 'bold' }}>Click to learn more!</a></p>
                </div>
            </div>
        </article>
    </>
);
}

InsightsCard.propTypes = {
    type: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    membersMessaged: PropTypes.number,
    totalImpressions: PropTypes.number,
    engagements: PropTypes.number,
    activeCampaigns: PropTypes.number,
    scheduledAlertNudges: PropTypes.number,
    activeLocationNudges: PropTypes.number,
};

InsightsCard.defaultProps = {
    type: 0,
    title: '',
    description: '',
    iconName: '',
    onClick: () => null,
};

export default InsightsCard;
